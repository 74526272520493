
  export default {
    inject: ['$validator'],
    components: {
      MPowerMultiselect: () => import('../MPowerMultiSelect'),
      MPowerValidateInput: () => import('../MPowerValidateInput'),
      UploadModalButton: () => import('../uploadButton/UploadModalButton'),
    },
    data() {
      return {
        artworks: [],
      };
    },
    props: {
      field: {
        type: Object,
        required: true,
      },
      fields: {
        type: Array,
        default: () => [],
      },
      index: {
        type: Number,
        required: true,
      },
    },
    watch: {
      artworks: {
        handler(val) {
          if (val.length) {
            this.field.value = val[0].fileLocation;
          } else if (this.field.value) this.field.value = null;
        },
        deep: true,
      },
    },
    computed: {
      label() {
        return this.field.referenceField.label || `Reference Field ${this.index}`;
      },
      options() {
        return this.field.referenceField.options || [];
      },
      type() {
        return this.field.referenceField.type;
      },
      required() {
        const { required, requiredBasedOn, requiredBasedOnOptions } = this.field.referenceField;
        const requiredBasedOnField = this.fields.find(({ referenceField }) => referenceField?._id === requiredBasedOn);
        const { value } = requiredBasedOnField || {};
        const hasOptions = (requiredBasedOnOptions || []).length > 0;
        return required && (!requiredBasedOn || (hasOptions ? requiredBasedOnOptions.includes(value) : !!value));
      },
      visible() {
        const { visibleBasedOn, visibleBasedOnOptions } = this.field.referenceField;
        const visibleBasedOnField = this.fields.find(({ referenceField }) => referenceField?._id === visibleBasedOn);
        const { value } = visibleBasedOnField || {};
        const hasOptions = (visibleBasedOnOptions || []).length > 0;
        return !visibleBasedOn || (hasOptions ? visibleBasedOnOptions.includes(value) : !!value);
      },
      description() {
        return this.field.referenceField.description;
      },
      optionsImages() {
        return (this.field.referenceField.optionsImages || []).reduce(
          (options, image) => ({ ...options, [image.option]: image }),
          {}
        );
      },
    },
  };
