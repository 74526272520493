import * as constants from '@/constants';
import { mapGetters } from 'vuex';
import { SHIPMENT_COUNTRIES } from '@/utils/constants';
import { PHONE_CODES } from '@mtntop/utils/src/components/PhoneField/utils/constants';
import { COUNTRIES_THAT_REQUIRE_STATES } from '@mtntop/utils/src/constants/shipment';

let mixinConstants = Object.assign({}, constants);

for (const prop in mixinConstants) {
  if (typeof constants[prop] !== 'function') {
    delete mixinConstants[prop];
  }
}
export default {
  computed: {
    ...mixinConstants,
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
    storeCountriesList() {
      if (this.storeInformation.countries?.length)
        return this.storeInformation.countries.map((e) => ({ ...e, text: e.name, value: e.code }));
      return Object.keys(SHIPMENT_COUNTRIES).map((text) => ({ text, value: SHIPMENT_COUNTRIES[text] }));
    },
    storeCountries() {
      return this.storeCountriesList.map((e) => ({
        countryPhoneCode: e.phoneCode || PHONE_CODES[e.value] || '+1',
        countryName: e.text,
        ISO: e.value,
        states: [],
      }));
    },
  },
  methods: {
    isCountryRequiresState(country) {
      return COUNTRIES_THAT_REQUIRE_STATES.includes(country);
    },
  },
};
