import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      referenceFields: [],
    };
  },
  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
    hasEnabledReferenceFields() {
      return this.enabledReferenceFields.length > 0;
    },
    enabledReferenceFields() {
      return this.referenceFields.filter(({ referenceField }) => !!referenceField.enabled);
    },
    enabledReferenceFieldsWithValue() {
      return this.enabledReferenceFields.filter(({ value }) => !!value);
    },
    enabledReferenceFieldsKeys() {
      return this.enabledReferenceFields.map(({ referenceField }) => `referenceField-${referenceField._id}`);
    },
    referenceFieldsList() {
      return this.referenceFields.map(({ referenceField }) => referenceField);
    },
    referenceFieldsKeys() {
      return this.referenceFields.map(({ referenceField }) => `referenceField-${referenceField._id}`);
    },
    enabledReferenceFieldsLabels() {
      return this.enabledReferenceFieldsKeys.reduce((fields, key, index) => {
        fields[key] = this.enabledReferenceFields[index].referenceField.label;
        return fields;
      }, {});
    },
  },
  methods: {
    async loadReferenceFields(modelFields = [], cartType = 'Cart', target = 'order') {
      const referenceFields = await this.$api.stores.loadReferenceFields({
        storeId: this.storeInformation._id,
        distributorId: this.storeInformation.distributorId?._id || this.storeInformation.distributorId,
        allowGlobal: true,
        target,
        cartType,
        includeWithoutCartType: true
      });
      this.referenceFields = referenceFields.map((field) => {
        const model = modelFields.find((m) => m.referenceField === field._id) || {};
        return {
          value: model.value || '',
          referenceField: { ...field, label: field.label || `Reference Field ${field._id}` },
        };
      });
    },
  },
};
